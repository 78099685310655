import React from "react"
import { graphql } from "gatsby"
import Link from "@material-ui/core/Link"

import HomeLayout from "../components/homelayout"
import SEO from "../components/seo"
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core"
import Socials from "../components/socials"
import styled from "styled-components"


const CardHeaderWrapped = styled(CardHeader)`
text-align: center;
`


class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <HomeLayout title={siteTitle}>
        <SEO title="Home" />
        <Card>
          <CardHeaderWrapped title="Louis des Landes" subheader={<Socials/>}  />
          <CardContent>
            <Typography paragraph={true}>
              Head of Engineering at{" "}
              <Link href="http://www.fleet.space/">Fleet Space Technologies.</Link>
            </Typography>
            <Typography paragraph={true}>
              PAX Enforcer.
            </Typography>
            <Typography paragraph={true}>
              Exploring the universe, starting from Low Earth Orbit.
            </Typography>
            <Typography paragraph={true}>
              Looking to contribute to the first crewed mission to Mars.
              </Typography>
          </CardContent>
        </Card>
      </HomeLayout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
